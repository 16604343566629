<template>
  <div v-if="isLoading == true"></div>
</template>
<script>
  import { FormWizard, TabContent } from "vue-form-wizard";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import axios from "axios";
  import interfaces from "@/templates/_interfaces.js";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import { isMobile } from "mobile-device-detect";
  import Loading from "@core/loading/Loading.vue";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      FormWizard,
      TabContent,
      ToastificationContent,
      Loading
    },
    data() {
      return {
        isLoading: true,
      };
    },
    methods: {
      init: function () {
        this.isLoading = true;
        this.urlcodeparam = this.$route.params.urlcodeparam;
        this.getToken();
        
      },
        getToken: function () {
            axios.post("self_registration/validate/checking-access", {urlcodeparam:this.urlcodeparam}).then((response) => {
            if (response.data.status === 2000) {
              localStorage.setItem("token", response.data.token);
              this.$router.push({ path: `/registerForm/${this.urlcodeparam}`});
            } else if(response.data.status === 4000) {
              this.isLoading = false;
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "This data has been created",
              //     icon: "EditIcon",
              //     variant: "warning",
              //     text: response.data.message,
              //   },
              // });
              // localStorage.clear();
              this.$router.push({ path: "/response-data-created" });
            }
          }).catch((error) => {
            this.isLoading = false;
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Data not exist",
              //     icon: "EditIcon",
              //     variant: "danger",
              //     text: response.data.message,
              //   },
              // });
              // localStorage.clear();
              this.$router.push({ path: "/error-404" });
          })
          .finally(() => {
            this.isLoading = true;
          });
        },
    },
    mounted() {
      this.init();
    },
  };
  </script>
  